<template>
  <div id="tankuang" v-if="isshow">
    <div id="tubiao">
      <img class="img" src="../assets/images/onepage/aaa1.png" style="" />
    </div>
    <p class="xiangqing">{{$t('frame.a')}}</p>
    <p class="line"></p>
    <div id="box">
      <div id="no" @click="less()">{{$t('frame.b')}}</div>
      <div id="sure" @click="getSwiper()">{{$t('frame.c')}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isshow: false,
      AUTHORIZATION: "",
      dataid: "",
      ck_status: "",
      user_iddd: "",
    };
  },
  created() {
    this.AUTHORIZATION = window.localStorage.getItem("token") || "";
    // 登陆成功标识
    this.user_iddd = window.localStorage.getItem("user_id") || "";

    console.log(this.user_iddd);
    console.log(this.AUTHORIZATION);
    console.log(11111111111111);
  },
  mounted() {
    if (this.user_iddd != "") {
      this.gettxorder();
    }
  },
  methods: {
    // 获取参数
    gettxorder() {
      console.log(122);
      this.$ajax({
        url: "/api/currency/order_info",
        method: "get",
        headers: {
          AUTHORIZATION: this.AUTHORIZATION,
        },
        data: {
          // token: this.token,
        },
      }).then((res) => {
        if (res.data.type == "ok") {
          // this.nowCoin = this.quotation[0].name;
          // 弹框确认传参所需id
          this.dataid = res.data.message.id;
          // 判断弹框是否出现
          this.ck_status = res.data.message.ck_status;

          if (this.ck_status == 0 && this.user_iddd != "") {
            this.isshow = true;
          } else if (this.ck_status == 1) {
            this.isshow = false;
          }

          console.log(this.dataid);
          console.log(this.ck_status);
        }
      });
    },

    // 判断弹框是否出现

    // box() {
    //   if (this.ck_status == 0 && this.user_iddd != "") {
    //     this.isshow = true;
    //   } else if (this.ck_status == 1) {
    //     this.isshow = false;
    //   }
    // },
    // 点击关闭弹窗
    less() {
      this.isshow = false;
      this.$ajax({
        url: "/api/currency/uporderstatus?dataid=" + this.dataid,
        method: "get",
        // data: {
        //   dataid: this.dataid,
        // },
      }).then((res) => {
        if (res.data.type == "ok") {
          this.gettxorder();
          this.$router.push({
            // path: " https://exchange.demo166.xyz/dist/#/transferLog",
          });
        }
      });
    },
    // 弹窗请求
    getSwiper() {
      console.log(this.dataid);
      this.$ajax({
        url: "/api/currency/uporderstatus?dataid=" + this.dataid,
        method: "get",
        // data: {
        //   dataid: this.dataid,
        // },
      }).then((res) => {
        if (res.data.type == "ok") {
          this.gettxorder();
          // location.href = "https://exchange.demo166.xyz/dist/#/dealFinance";
          this.$router.push({
          path: "https://www.bitcoin33333.com/dist/#/dealFinance",
          });
        }
      });
    },
  },
};
</script>
<style scoped>
#tankuang {
  position: fixed;
  left: 0;
  right: 0;
  width: 30%;
  height: 200px;
  margin: auto;
  top: 100px;
  padding-top: 15px;

  box-sizing: border-box;
  /* background: #FEFEFE; */
  border-radius: 5px;
  background-color: gainsboro;
  z-index: 999;
}
#tubiao {
  margin: auto;
  width: 54px;
  height: 54px;
  border-radius: 50%;
}
.xiangqing {
  text-align: center;
  color: black;
  margin-bottom: 10px;
  margin-top: 10px;
}
.line {
  border-bottom: 1px solid #999999;
}
#sure {
  font-size: 15px;
  color: #3b7ded;
}
#box {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  color: #3b7ded;
}
.img {
  width: 100%;
  height: 100%;
}
</style>