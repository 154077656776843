import { render, staticRenderFns } from "./transferMicro.vue?vue&type=template&id=6b673caa&scoped=true"
import script from "./transferMicro.vue?vue&type=script&lang=js"
export * from "./transferMicro.vue?vue&type=script&lang=js"
import style0 from "./transferMicro.vue?vue&type=style&index=0&id=6b673caa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b673caa",
  null
  
)

export default component.exports