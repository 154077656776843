import { render, staticRenderFns } from "./dealCenter.vue?vue&type=template&id=6f175ee6&scoped=true"
import script from "./dealCenter.vue?vue&type=script&lang=js"
export * from "./dealCenter.vue?vue&type=script&lang=js"
import style0 from "./dealCenter.vue?vue&type=style&index=0&id=6f175ee6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f175ee6",
  null
  
)

export default component.exports